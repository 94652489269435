const FeatureAppHeader = () => {
  return (
    <>
      <div className="feature-app--header">
        <h2 className="feature-app--header-title">How it works</h2>
        <div className="feature-app--header-desc">
          <p className="feature-app--header-tdesc">
            Collect the points and redeem it with various rewards
          </p>
        </div>
      </div>
    </>
  );
};

export default FeatureAppHeader;
